<template>
  <div class="contents">
    <CmsPage v-if="!pending && page" :page :components />

    <GenericPageGenres v-else-if="!pending && !page" />
  </div>
</template>

<script lang="ts" setup>
const { pending, page, components } = useCmsPageFetch()

defineI18nRoute({
  paths: {
    en: '/movies/genres',
    de: '/filme/genres',
    es: '/peliculas/generos',
  },
})

defineOptions({
  name: 'PageMoviesGenres',
})
</script>
