<template>
  <div class="contents">
    <CmsPage v-if="!pending && page" :page :components />

    <GenericPageGenre v-else-if="!pending && !page" :genre />
  </div>
</template>

<script lang="ts" setup>
import type { Genre } from '#gql/default'

interface Props {
  genre: Genre
}

defineProps<Props>()

const { pending, page, components } = await useCmsPageFetch()

defineOptions({
  name: 'PageMoviesGenreSlugIndex',
})
</script>
