<template>
  <slot :genre="genre" :pending="pending" />
</template>

<script lang="ts" setup>
interface Props {
  genreSlug: string
  throwError?: boolean
}

const COMPONENT_NAME = 'GenreFetch'
const props = withDefaults(defineProps<Props>(), {
  throwError: false,
})
const { t } = useI18n()

const { data, pending, execute } = await useAsyncData(
  getGqlFetchKey(COMPONENT_NAME, props.genreSlug),
  () =>
    GqlFetchGenre(
      {
        urlSlug: props.genreSlug,
      },
      { immediate: false }
    )
)

await execute()

if (!data.value?.genre?.id && props.throwError) {
  showError({
    statusCode: 404,
    statusMessage: t('errors.itemNotFound', { item: t('item.genre') }),
  })
}

const genre = computed(() => data.value?.genre)

defineOptions({
  name: COMPONENT_NAME,
})
</script>

<i18n>
de:
  errors:
    itemNotFound: "{item} nicht gefunden"
  item:
    genre: "Genre"
es:
  errors:
    itemNotFound: "{item} no encontrado"
  item:
    genre: "Género"
</i18n>
